import React, { useState, useEffect } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { Wheel } from "react-custom-roulette";
import logo from "../assets/images/image-2.png";
import axios from "axios";
import Confetti from "react-confetti";
import { BaseUrl } from "../constants/BaseUrl";
import { useNavigate } from "react-router-dom";

const data = [
  {
    id: "0",
    option: "1 PLAT OFFERT",
    style: { backgroundColor: "black", textColor: "white" },
  },
  {
    id: "1",
    option: "TON PLAT À -50%",
    style: { backgroundColor: "white", textColor: "black" },
  },
  {
    id: "2",
    option: "1 PLAT OFFERT",
    style: { backgroundColor: "black", textColor: "white" },
  },
  {
    id: "3",
    option: "TON PLAT À -50%",
    style: { backgroundColor: "white", textColor: "black" },
  },
  {
    id: "4",
    option: "1 PLAT OFFERT",
    style: { backgroundColor: "black", textColor: "white" },
  },
  {
    id: "5",
    option: "TON PLAT À -50%",
    style: { backgroundColor: "white", textColor: "black" },
  },
];

function Footer() {
  let navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [couponNum, setCouponNum] = useState(1);
  const [mustSpin, setMustSpin] = useState(false);
  const [open, setOpen] = useState(false);
  const [spinView, setSpinView] = useState(true);
  const [emailModal, setEmailModal] = useState(false);
  const [emailFndModal, setEmaiFndlModal] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [email, setEmailsData] = useState();
  const [onceSend, setOnceSend] = useState(false);
  const [error, setError] = useState(false);
  const [alreadyError, setAlreadyError] = useState("");
  const [alreadyShownError, setAlreadyShownError] = useState(false);
  const screenW = window.screen.width;
  const screenH = window.screen.height;
  const onHandleChange = (e) => {
    const dataEmail = { ...email };
    dataEmail[e.target.name] = e.target.value;
    setEmailsData({ ...dataEmail });
  };
  useEffect(() => {
    localStorage.setItem("noSpin", true);
    if (localStorage.getItem("spinned")) {
      setSpinView(false);
    }
    if (localStorage.getItem("get")) {
      setOnceSend(true);
    }
  }, []);
  const sendEmailsData = () => {
    const winGift = data[localStorage.getItem("details")].option;

    if (email !== undefined && email.email && email.name && winGift) {
      let sendData = {
        email: email.email,
        name: email.name,
        gift: winGift,
      };
      axios
        .post(`${BaseUrl}email/send-email`, sendData)
        .then((res) => {
          // console.log(res);
          if (res.status === 200) {
            setEmaiFndlModal(false);
            setEmailModal(false);
            localStorage.setItem("get", true);
            setOnceSend(true);
          }
          if (res.status === 201) {
            setAlreadyError(res.data.message);
            setAlreadyShownError(true);
            setTimeout(() => {
              setAlreadyShownError(false);
            }, 3000);
          }
        })
        .catch((error) => {
          if (error) {
            setEmailError(true);
            setTimeout(() => {
              setEmailError(false);
            }, 3000);
          }
        });
    } else {
      setError(true);
      setTimeout(() => {
        setError(false);
      }, 3000);
    }
  };
  const handleCloseEmailModal = () => {
    setEmailModal(false);
  };
  const handleCloseEmailFndModal = () => {
    setEmailFndModal(false);
  };
  // function getRandomInt(max) {
  //   return Math.floor(Math.random() * max);
  // }
  const onClickcoupon = () => {
    if (
      localStorage.getItem("noSpin") &&
      localStorage.getItem("instagram") == 10
    ) {
      // const newCouponNum = getRandomInt(5);
      axios
        .get(`${BaseUrl}email/get-gift-count`)
        .then((res) => {
          // console.log(res.data, "details234");
          if (res.data.total <= 150) {
            setCouponNum(2);
            localStorage.setItem("details", 2);
          }else{
            setCouponNum(3);
            localStorage.setItem("details", 3);
          }
          setTimeout(() => {
            setMustSpin(true);            
          }, 0);
        })
        .catch((error) => {
          setCouponNum(2);
          localStorage.setItem("details", 2);
          setMustSpin(true);
        });
      // if (newCouponNum === 0) {
      //   setCouponNum(3);
      //   localStorage.setItem('details', 3)
      // } else {
      //   setCouponNum(newCouponNum);
      //   localStorage.setItem('details', newCouponNum)
      // }

      // setMustSpin(true);
    }
    if (
      localStorage.getItem("noSpin") &&
      localStorage.getItem("instagram") === null
    ) {
      setOpenModal(true);
    }
  };
  const redirect = () => {
    navigate("/login");
  };

  return (
    <>
      {/* {console.log(email)} */}
      <div
        className="h-100 w-100 position-absolute d-flex flex-column ng-star-inserted"
        style={{ backgroundColor: "#212121", overflow: "hidden" }}
      >
        {spinView === false && (
          <Confetti colors={["#b6c586"]} width={screenW} height={screenH} />
        )}
        <div className="h-50 d-flex flex-column justify-content-end align-items-center text-center">
          {/* <div className="bg-logo mb-2 ng-star-inserted" /> */}
          <img
            className="bg-logo mb-2 ng-star-inserted"
            src={logo}
            alt="logo"
            onDoubleClick={redirect}
          />
          {spinView && (
            <div
              className="fs-4 mb-0 ng-star-inserted font-face-gm"
              style={{
                textTransform: "uppercase",
                fontWeight: 600,
                color: "#ffffff",
                lineHeight: "1.25!important",
              }}
            >
              TOURNEZ LA ROUE POUR
            </div>
          )}
          {spinView === false ? (
            <div
              className="fs-4 mb-0 ng-star-inserted font-face-gm"
              style={{
                textTransform: "uppercase",
                fontWeight: 600,
                color: "#ffffff",
                lineHeight: "1.25!important",
              }}
            >
              BRAVO, C'EST GAGNÉ !
            </div>
          ) : (
            ""
          )}
          {spinView === false ? (
            <div
              className="fs-4 mb-0 ng-star-inserted font-face-gm"
              style={{
                textTransform: "uppercase",
                fontWeight: 600,
                color: "#B6C586",
                lineHeight: "1.25!important",
              }}
            >
              <span style={{ fontFamily: "sans-serif ", fontSize: "30px" }}>
                {data[localStorage.getItem("details")].option}
              </span>
            </div>
          ) : (
            ""
          )}
          {spinView === false
            ? onceSend === true && (
                <div
                  className="fs-4 mb-0 ng-star-inserted font-face-gm"
                  style={{
                    textTransform: "uppercase",
                    fontWeight: 600,
                    color: "#ffffff",
                    lineHeight: "1.25!important",
                  }}
                >
                  E-mail envoyé à votre adresse E-mail, veuillez également
                  vérifier le dossier spam
                </div>
              )
            : ""}
          {spinView === false
            ? onceSend === false && (
                <div
                  className="fs-4 mb-0 ng-star-inserted font-face-gm"
                  style={{
                    textTransform: "uppercase",
                    fontWeight: 600,
                    color: "#ffffff",
                    lineHeight: "1.25!important",
                  }}
                >
                  Souhaitez-vous récupérer votre cadeau ou l'offrir à un ami ?
                </div>
              )
            : ""}
          {spinView && (
            <div
              className="fs-4 mb-0 ng-star-inserted font-face-gm"
              style={{
                textTransform: "uppercase",
                fontWeight: 600,
                color: "#B6C586",
                lineHeight: "1.25!important",
              }}
            >
              DÉCOUVRIR VOTRE CADEAU
            </div>
          )}
          {spinView && (
            <div
              onClick={() => onClickcoupon()}
              className="rounded-pill text-uppercase cursor-pointer fw-bold fs-5 py-2 px-4 lh-sm font-face-gm"
              style={{ color: "#000000", backgroundColor: "#B6C586" }}
            >
              Tourner la roue
            </div>
          )}
        </div>

        <div className="h-50 position-relative ng-star-inserted">
          <div className="row">
            {spinView === false
              ? onceSend === false && (
                  <div className="d-flex flex-column justify-content-end align-items-center text-center">
                    <div
                      onClick={() => setEmailModal(true)}
                      className="rounded-pill text-uppercase cursor-pointer fw-bold fs-5 py-2 px-4 lh-sm font-face-gm"
                      style={{
                        color: "#000000",
                        backgroundColor: "#B6C586",
                        margin: "10px 0px",
                      }}
                    >
                      J'EN PROFITE
                    </div>
                    <div
                      onClick={() => setEmaiFndlModal(true)}
                      className="rounded-pill text-uppercase cursor-pointer fw-bold fs-5 py-2 px-4 lh-sm font-face-gm"
                      style={{ color: "#000000", backgroundColor: "#B6C586" }}
                    >
                      L'OFFRIR À UN(E) AMI(E)
                    </div>
                  </div>
                )
              : ""}
            {spinView && (
              <div className="offset-md-4 ng-tns-c81-0 ng-star-inserted font-face-gm">
                <div style={{ marginLeft: 30 }}>
                  <Wheel
                    fontSize={14}
                    radiusLineColor="#B6C586"
                    outerBorderColor="#B6C586"
                    outerBorderWidth={10}
                    mustStartSpinning={mustSpin}
                    prizeNumber={couponNum}
                    onStopSpinning={() => {
                      setMustSpin(false);
                      // handleOpen();
                      setSpinView(false);
                      localStorage.setItem("spinned", true);
                    }}
                    // mustStartSpinning={mustSpin}
                    // prizeNumber={3}
                    data={data}
                  />
                </div>
              </div>
            )}
          </div>
        </div>

        {/* Modals */}
        <Modal centered isOpen={openModal}>
          <ModalBody>
            <div className='class="h3 text-uppercase mb-3 text-dark font-face-gm'>
              REJOINS-NOUS
            </div>
            <div className="fw-semi-bold ng-star-inserted font-face-gm">
              Rejoins-nous sur Instagram puis reviens sur cette page pour
              participer à notre jeu !
            </div>
            <img
              style={{ width: "100%" }}
              src={`https://larouedelafortune.splash360.fr/assets/images/back-arrow.jpg`}
              alt="back-arrow"
              className="img-fluid py-3 ng-star-inserted"
            />
            <a
              target="_blank"
              rel="noreferrer"
              onClick={() =>
                localStorage.setItem("instagram", [10], setOpenModal(false))
              }
              style={{ color: "black", textDecoration: "none" }}
              href="https://www.instagram.com/asianhouse.officiel/?hl=fr"
            >
              <div
                className="rounded-pill cursor-pointer text-uppercase fw-semi-bold p-2 ng-star-inserted font-face-gm"
                style={{ backgroundColor: "#B6C586" }}
              >
                <div style={{ textAlign: "center" }}>
                  <i className="fa fa-instagram"></i> Instagram
                </div>
              </div>
            </a>
          </ModalBody>
        </Modal>
        <Modal isOpen={open}>
          <ModalHeader
            close={
              <button
                className="btn btn-default"
                style={{ fontSize: "25px" }}
                onClick={() => setOpen(false)}
              >
                ×
              </button>
            }
            // toggle={}
          >
            {" "}
          </ModalHeader>
          <div>
            <h5 hidden>{couponNum}</h5>
            <p style={{ padding: "15px" }}>{data[couponNum].option}</p>
          </div>
        </Modal>
        <Modal isOpen={emailModal} onClose={handleCloseEmailModal}>
          <ModalHeader
            close={
              <button
                className="btn btn-default"
                style={{ fontSize: "25px" }}
                onClick={handleCloseEmailModal}
              >
                ×
              </button>
            }
            // toggle={}
          >
            <div className="h4 text-uppercase mb-3 text-dark font-face-gm">
              Entrez votre e-mail pour recevoir le cadeau
            </div>
          </ModalHeader>
          <ModalBody>
            <div>
              <div className="offset-md-2 col-md-8">
                <label htmlFor="review">Prénom</label>
                <input
                  // style={{ padding: "14px 25px" }}
                  type="text"
                  className="form-control"
                  id="name"
                  // placeholder="Name"
                  required
                  name="name"
                  onChange={(e) => onHandleChange(e)}
                />
              </div>
              <div
                style={{ marginTop: "10px" }}
                className="offset-md-2 col-md-8"
              >
                <label htmlFor="review">E-mail</label>
                <input
                  // style={{ padding: "14px 25px" }}
                  type="email"
                  className="form-control"
                  id="email"
                  // placeholder="E-mail"
                  required
                  name="email"
                  onChange={(e) => onHandleChange(e)}
                />
              </div>
              <div
                className="offset-md-2 col-md-8"
                style={{ marginTop: "10px" }}
              >
                {error && (
                  <p style={{ color: "red" }}>
                    {" "}
                    Please Fill the name and email
                  </p>
                )}
                {alreadyShownError && (
                  <p style={{ color: "red" }}>{alreadyError}</p>
                )}
              </div>
              <div
                className="offset-md-7 col-md-2"
                style={{ marginTop: "10px" }}
              >
                {emailError && (
                  <p style={{ color: "red" }}> No recipients defined</p>
                )}
                <button
                  onClick={sendEmailsData}
                  style={{ backgroundColor: "#B6C586", color: "white" }}
                  className="btn btn-default font-face-gm"
                >
                  Recevoir
                </button>
              </div>
              {/* <p style={{ padding: "15px" }}>{data[couponNum].option}</p> */}
            </div>
          </ModalBody>
        </Modal>
        <Modal isOpen={emailFndModal} onClose={handleCloseEmailFndModal}>
          <ModalHeader
            close={
              <button
                className="btn btn-default"
                style={{ fontSize: "25px" }}
                onClick={handleCloseEmailFndModal}
              >
                ×
              </button>
            }
            // toggle={}
          >
            <div className="h4 text-uppercase mb-3 text-dark font-face-gm">
              ENTREZ L'E-MAIL DE VOTRE AMI POUR LUI OFFRIR CE CADEAU
            </div>
          </ModalHeader>
          <ModalBody>
            <div>
              <div className="offset-md-2 col-md-8">
                <label htmlFor="review">Prénom de votre ami</label>
                <input
                  // style={{ padding: "14px 25px" }}
                  type="text"
                  className="form-control"
                  id="name"
                  // placeholder="Name"
                  required
                  name="name"
                  onChange={(e) => onHandleChange(e)}
                />
              </div>
              <div
                style={{ marginTop: "10px" }}
                className="offset-md-2 col-md-8"
              >
                <label htmlFor="review">E-mail de votre ami</label>
                <input
                  // style={{ padding: "14px 25px" }}
                  type="email"
                  className="form-control"
                  id="email"
                  // placeholder="E-mail"
                  required
                  name="email"
                  onChange={(e) => onHandleChange(e)}
                />
              </div>
              <div
                className="offset-md-2 col-md-8"
                style={{ marginTop: "10px" }}
              >
                {error && (
                  <p style={{ color: "red" }}>
                    {" "}
                    Please Fill the name and email
                  </p>
                )}
                {alreadyShownError && (
                  <p style={{ color: "red" }}>{alreadyError}</p>
                )}
              </div>
              <div
                className="offset-md-7 col-md-2"
                style={{ marginTop: "10px" }}
              >
                {emailError && (
                  <p style={{ color: "red" }}> No recipients defined</p>
                )}
                <button
                  onClick={sendEmailsData}
                  style={{ backgroundColor: "#B6C586", color: "white" }}
                  className="btn btn-default font-face-gm"
                >
                  Envoyer
                </button>
              </div>
              {/* <p style={{ padding: "15px" }}>{data[couponNum].option}</p> */}
            </div>
          </ModalBody>
        </Modal>
      </div>
    </>
  );
}

export default Footer;
