
// export const BaseUrl = 'https://asian-house-fr.vercel.app/'
// export const BaseUrl = 'https://asian-house.herokuapp.com/'
// export const BaseUrl = 'https://asian-house-backend.herokuapp.com/'
export const BaseUrl = 'https://asia-house2.herokuapp.com/'
export const token = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  };
